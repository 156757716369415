import React from "react"

const Logo = ({ currentColor }) => {
  return (
    <div className="logo">
      <svg viewBox="0 0 1114.69 193.15">
        <g>
          <path
            style={{ fill: currentColor }}
            d="M805.78,75.43V53.97c0-19.62-12.53-33.33-38.09-33.33c-25.28,0-38.09,13.28-38.09,33.33v86.34
		c0,17,9.66,31.85,38.09,31.85c27.86,0,38.09-13.85,38.09-31.85v-31.9h-33.61v37.57c0,2.47-2.01,4.48-4.48,4.48
		s-4.48-2.01-4.48-4.48V46.92c0-2.47,2.01-4.48,4.48-4.48s4.48,2.01,4.48,4.48v28.51H805.78z"
          />
          <path
            style={{ fill: currentColor }}
            d="M126.7,145.99c0,2.47-1.9,4.48-4.39,4.48c-2.65,0-4.39-2.01-4.39-4.48V22.4c-8.36,0.12-25.81-0.09-33.61,0
		c0,0,0,88.74,0,117.13c0,3.23-0.17,5.74,0,8.81c0.46,8.45,2.18,15.88,8.29,20.11c3.37,2.33,8.04,3.72,14.64,3.72
		c9.8,0,15.98-5.08,19.46-12.91v11.45h33.61V22.4H126.7V145.99z"
          />
          <path
            style={{ fill: currentColor }}
            d="M688.26,145.99c0,2.47-1.9,4.48-4.39,4.48c-2.65,0-4.39-2.01-4.39-4.48V22.4c-8.36,0.12-25.81-0.09-33.61,0
		c0,0,0,88.74,0,117.13c0,3.23-0.17,5.74,0,8.81c0.46,8.45,2.18,15.88,8.29,20.11c3.37,2.33,8.04,3.72,14.64,3.72
		c9.8,0,15.98-5.08,19.46-12.91v11.45h33.61V22.4h-33.61V145.99z"
          />
          <path
            style={{ fill: currentColor }}
            d="M914.35,145.99c0,2.47-1.9,4.48-4.39,4.48c-2.65,0-4.39-2.01-4.39-4.48V22.4c-8.36,0.12-25.81-0.09-33.61,0
		c0,0,0,88.74,0,117.13c0,3.23-0.17,5.74,0,8.81c0.46,8.45,2.18,15.88,8.29,20.11c3.37,2.33,8.04,3.72,14.64,3.72
		c9.8,0,15.98-5.08,19.46-12.91v11.45h33.61V22.4h-33.61V145.99z"
          />
          <path
            style={{ fill: currentColor }}
            d="M629.59,24.37c-3.37-2.33-8.04-3.72-14.64-3.72c-9.8,0-15.98,5.17-19.46,13V22.4h-33.61v148.14h33.61V46.92
		c0-2.47,1.72-4.48,4.39-4.48c2.43,0,4.39,1.76,4.39,5.23v51.66c7.42,0.01,26.42,0.01,33.61,0l0.17-45.35
		c0-3.23-0.01-6.42-0.17-9.49C637.41,36.03,635.7,28.6,629.59,24.37z"
          />
          <path
            style={{ fill: currentColor }}
            d="M403.5,24.37c-3.37-2.33-8.04-3.72-14.64-3.72c-9.8,0-15.98,5.17-19.46,13V22.4h-33.61v148.14h33.61V46.92
		c0-2.47,1.59-4.48,4.39-4.48c2.61,0,4.39,2.01,4.39,4.48v52.41c7.42,0.01,26.42,0.01,33.61,0l0.17-45.35
		c0-3.23-0.01-6.42-0.17-9.49C411.32,36.03,409.6,28.6,403.5,24.37z"
          />
          <path
            style={{ fill: currentColor }}
            d="M1023.41,24.37c-3.37-2.33-8.04-3.72-14.64-3.72c-9.8,0-15.98,5.17-19.46,13V22.4H955.7v148.14h33.61V46.92
		c0-2.47,1.59-4.48,4.39-4.48c2.61,0,4.39,2.01,4.39,4.48v52.41c7.42,0.01,26.42,0.01,33.61,0l0.17-45.35
		c0-3.23-0.01-6.42-0.17-9.49C1031.24,36.03,1029.52,28.6,1023.41,24.37z"
          />
          <path
            style={{ fill: currentColor }}
            d="M235.77,24.37c-3.37-2.33-8.04-3.72-14.64-3.72c-9.8,0-15.98,5.17-19.46,13V22.4h-33.61v170.75h33.61
		c0.08-8.81-0.06-25.96,0-33.89c3.48,7.83,9.67,12.91,19.46,12.91c6.6,0,11.27-1.39,14.64-3.72c6.11-4.23,7.83-10.18,8.29-18.63
		V44.48C243.59,36.03,241.87,28.6,235.77,24.37z M210.44,145.99c0,2.47-1.83,4.48-4.39,4.48c-2.48,0-4.39-2.01-4.39-4.48V46.92
		c0-2.47,1.49-4.48,4.39-4.48c3.19,0,4.39,2.01,4.39,4.48V145.99z"
          />
          <path
            style={{ fill: currentColor }}
            d="M290.01,20.65c-25.28,0-38.09,13.28-38.09,33.33v84.86c0,17,9.66,33.33,38.09,33.33
		c27.86,0,38.09-15.33,38.09-33.33v-30.42h-33.61v37.57c0,2.47-2.01,4.48-4.48,4.48c-2.47,0-4.48-2.01-4.48-4.48V99.33h42.57V53.98
		C328.09,34.36,315.56,20.65,290.01,20.65z M294.48,75.43h-8.96V46.92c0-2.47,2.01-4.48,4.48-4.48c2.47,0,4.48,2.01,4.48,4.48
		L294.48,75.43L294.48,75.43z"
          />
          <path
            style={{ fill: currentColor }}
            d="M1114.69,99.33V53.98c0-19.62-12.54-33.33-38.09-33.33c-25.28,0-38.09,13.28-38.09,33.33v84.86
		c0,17,9.66,33.33,38.09,33.33c27.86,0,38.09-15.33,38.09-33.33v-30.42h-33.61v37.57c0,2.47-2.01,4.48-4.48,4.48
		s-4.48-2.01-4.48-4.48V99.33H1114.69z M1072.13,46.92c0-2.47,2.01-4.48,4.48-4.48s4.48,2.01,4.48,4.48v28.51h-8.96V46.92z"
          />
          <path
            style={{ fill: currentColor }}
            d="M854.32,0h-16.8h-16.8c0,5.42,0,17.14,0,22.4h-7.24v25.65h7.24V153.9c0,9.28,7.52,16.8,16.8,16.8
		c7.85,0,18.98-0.09,26.62,0v-20.24h-9.82V48.05h9.82V22.4h-9.82C854.33,17.14,854.33,5.42,854.32,0z"
          />
          <path
            style={{ fill: currentColor }}
            d="M544.35,0h-16.8h-16.8c0,5.42,0,17.14,0,22.4h-7.24v25.65h7.24V153.9c0,9.28,7.52,16.8,16.8,16.8
		c7.85,0,18.98-0.09,26.62,0v-20.24h-9.82V48.05h9.82V22.4h-9.82C544.35,17.14,544.35,5.42,544.35,0z"
          />
          <path
            style={{ fill: currentColor }}
            d="M469.67,85.35c-11.21-8.92-16.42-13.66-16.42-26.18V46.92c0-2.47,2.42-4.48,4.9-4.48
		c2.47,0,4.83,2.01,4.83,4.48v28.51h32.84c0,0,0.04-19.38,0.04-22.1c0-6.64-1.37-32.68-38.13-32.68c-24.02,0-37.94,13-37.94,33.05
		c0,8.17,0.01,2.8,0.01,5.46c0,19.74,6.24,27.51,16.2,36.72c10.23,9.46,26.98,19.42,26.98,30.81v19.3c0,2.47-2.36,4.48-4.83,4.48
		c-2.47,0-4.9-2.01-4.9-4.48V115.4c0,0-31.93,0-32.86,0v24.92c0,18.94,12.06,31.85,37.34,31.85c27.86,0,38.88-14.04,38.88-32.97
		v-10.56C496.62,106.29,484.04,96.79,469.67,85.35z"
          />
          <path
            style={{ fill: currentColor }}
            d="M49.88,85.4c-11.21-8.92-16.42-13.66-16.42-26.18V46.97c0-2.47,2.42-4.48,4.9-4.48c2.47,0,4.83,2.01,4.83,4.48
		v28.51h32.84c0,0,0.04-19.38,0.04-22.1c0-6.64-1.37-32.68-38.13-32.68C13.92,20.7,0,33.7,0,53.75c0,8.17,0.01,2.8,0.01,5.46
		c0,19.74,6.24,27.51,16.2,36.72c10.23,9.46,26.98,19.42,26.98,30.81v19.3c0,2.47-2.36,4.48-4.83,4.48s-4.9-2.01-4.9-4.48v-30.59
		c0,0-31.93,0-32.86,0v24.92c0,18.94,12.06,31.85,37.34,31.85c27.86,0,38.88-14.04,38.88-32.97v-10.56
		C76.82,106.34,64.25,96.84,49.88,85.4z"
          />
        </g>
      </svg>
    </div>
  )
}

export default Logo
